import * as Sentry from '@sentry/vue'
import axios from 'axios'
import isFinite from 'lodash.isfinite'
import get from 'lodash.get'
import merge from 'lodash.merge'
import sanitize from 'sanitize-filename'
import toaster from '@/modules/toaster'
import translations from '@/modules/translations'
import { getPreferences } from '@/modules/storage'
import { loadProgressBar } from '@/modules/axiosProgressBar'
import watchReportRecursive from '@/modules/reportWatcher'
import { venueIdFromUrl, venueIdToUrl, arrayToObjectById, guid, promptDownload, setTimeoutAsync } from '@/modules/utils'
import { logEvent } from '@/modules/logger.js'
import { getFirebaseUserToken } from '@/firebase'

/**
 * @type {string}
 */
let baseURL, openaiBaseURL
let singleInstanceRequests = { 'variances/inventories': {}, 'items/find': {} }

switch (window.location.hostname) {
  case 'feature1.wisk.dev':
  case 'feature2.wisk.dev':
  case 'web.wisk.dev':
    baseURL = 'https://api.wisk.dev/'
    openaiBaseURL = 'https://wisk.dev/api/wisk-openai/api'
    break
  case 'web.wisk.cool':
    baseURL = 'https://api.wisk.cool/'
    openaiBaseURL = 'https://wisk.cool/api/wisk-openai/api'
    break
  case 'web.wisk.restaurant':
    baseURL = 'https://api.wisk.restaurant/'
    break
  case 'web.wisksolutions.com':
    baseURL = 'https://api.wisksolutions.com/'
    break
  default:
    baseURL = 'https://api.wisk.ai/'
    openaiBaseURL = 'https://wisk.restaurant/api/wisk-openai/api'
}

if (getPreferences('environment') !== null) {
  switch (getPreferences('environment')) {
    case 1:
      baseURL = 'https://api.wisk.cool/'
      openaiBaseURL = 'https://wisk.cool/api/wisk-openai/api'
      break
    case 2:
      baseURL = 'https://api.wisk.dev/'
      openaiBaseURL = 'https://wisk.dev/api/wisk-openai/api'
      break

    default:
      baseURL = 'https://api.wisk.ai/'
      openaiBaseURL = 'https://wisk.restaurant/api/wisk-openai/api'
      break
  }
}

if (import.meta.env.VITE_API_URL_OVERRIDE) {
  baseURL = import.meta.env.VITE_API_URL_OVERRIDE
  console.warn('***** USING VITE_API_URL_OVERRIDE: ', import.meta.env.VITE_API_URL_OVERRIDE)
}

/**
 * @type {AxiosInstance}
 */
const apiRequest = axios.create({
  baseURL,
  headers: {
    'X-venue_id': venueIdFromUrl() || localStorage.getItem('X-venue_id') || '',
    'X-wisk_source': 'web',
    'X-Requested-With': 'XMLHttpRequest'
    // 'X-app_version': new Date(import.meta.env.BUILD_TIMESTAMP).toISOString()
  }
})
// IMPORTANT: keep these two interceptors as first in order. for measurement accuracy
apiRequest.interceptors.request.use(
  request => {
    request.meta = request.meta || {}
    request.meta.requestStartedAt = new Date().getTime()
    return request
  },
  null,
  { synchronous: true }
)

const getIpInfo = () => axios.get('https://ipapi.co/json/'),
  callTestAIApi = payload => axios.post(`${openaiBaseURL}/group-items`, payload, { baseURL: '' }),
  postErrorInfo = payload =>
    apiRequest.post('slack/web-errors', {
      venue_id: venueIdFromUrl(),
      user_id: getPreferences('currentUserId'),
      url: window.location.href,
      // description: '...',
      // payload: {},
      timestamp: new Date().toISOString(),
      ...payload
    })

const authenticate = (skipExtra, caller) =>
  new Promise((resolve, reject) => {
    window.WiskGlobals = window.WiskGlobals || {}

    let params = { caller }
    if (window.WiskGlobals.invitationUUID) {
      params.invitation_uuid = window.WiskGlobals.invitationUUID
    }
    if (skipExtra) {
      params.skip_extra = true //add only if true to keep the params empty otherwise
    }

    apiRequest
      .get('authenticate', { params })
      .then(result => {
        if (result && result.venue && result.venue.id) {
          apiRequest.defaults.headers['X-venue_id'] = result.venue.id
          localStorage.setItem('X-venue_id', result.venue.id)
          venueIdToUrl(result.venue.id)
        }

        setTimeout(() => {
          window.WiskGlobals.invitationUUID = null
        }, 0)
        resolve(result)
      })
      .catch(error => {
        reject(error)
      })
  })

apiRequest.interceptors.request.use(
  async request => {
    if (get(singleInstanceRequests, request.url)) {
      if (get(singleInstanceRequests, `${request.url}.abortSignal.abort`)) {
        singleInstanceRequests[request.url].abortSignal.abort()
      }

      const newAbortController = new AbortController()
      request.signal = newAbortController.signal

      singleInstanceRequests[request.url].abortSignal = newAbortController
    }

    request.headers.Authorization = (await getFirebaseUserToken()) || request.headers.Authorization
    request.headers['X-Request-Id'] = guid()

    if (request?.method === 'post') {
      if (Array.isArray(request?.data?.operations)) {
        //detach to avoid false change detection!!!
        request.data.operations = request.data.operations.map(o => merge({}, o))

        request.data.operations.forEach(operation => {
          if (operation?.type && !operation.date) {
            operation.date = new Date().toISOString()
            operation.platform = 'web'
          }
        })
      }

      //check to make sure no undefined is in the url and send it to Sentry
      if (request.url.includes('undefined')) {
        Sentry.withScope(scope => {
          scope.setExtra('API URL', request.url)
          scope.setExtra('API Payload', JSON.stringify(request.data))
          scope.setExtra('API Params', request.params)
          scope.setExtra('browser address', window.location.href)
          scope.setExtra('HTTP Method', request.method)
          scope.setExtra('Global actions', JSON.stringify(window?.WiskGlobals?.globalActions))
          Sentry.captureException(new Error(`Found undefined in the url (${request.url})`))
        })
      }

      if (request?.data?.operation) {
        if (!request.data.operation.date) {
          request.data.operation.date = new Date().toISOString()
          request.data.operation.platform = 'web'
        }
      }
    }
    return request
  },
  error => Promise.reject(error)
)
let getChanges = () => {}
const setGetChanges = callback => {
  getChanges = callback
}
loadProgressBar({ showSpinner: false }, apiRequest)
apiRequest.interceptors.response.use(
  response => {
    if (get(singleInstanceRequests, `${response.config.url}.abortSignal`)) {
      delete singleInstanceRequests[response.config.url].abortSignal
    }

    if (response.data instanceof Blob) {
      return response
    }

    const url = response?.config?.url || '',
      method = response?.config?.method || '',
      isPost = method === 'post',
      isSavingData = url.includes('/id/') || url.startsWith('batch/') || url.includes('reorder') || url.includes('/move-measurements') || url.includes('/start'),
      isNotBatchImport = !url.includes('/batch-import/'),
      isNotFlatten = !url.includes('flatten'),
      disableNotification = response?.config?.disableSuccessNotification,
      returnFullResponse = response?.config?.returnFullResponse,
      itemOperationError = get(response, 'data.info') || get(response, 'data.not_updated_venue_bottles')

    let message = translations.translate('txtGenericOperationSuccess')

    if (isPost && isSavingData && isNotBatchImport && isNotFlatten && !itemOperationError) {
      /*if (response.data && response.data.message) {
        text = response.data.message
      }*/

      if (!disableNotification) {
        toaster.apiChangesSavedNotification(message)
      }

      getChanges()
    }

    return returnFullResponse ? response : response.data.data || response.data
  },

  async error => {
    let contentType = get(error, 'request.responseType'),
      jsonResponse,
      url = get(error, 'config.url'),
      filteredErrorCodes = ['ERROR-67', 'ERROR-90', 'ERROR-119', 'ERROR-140', 'ERROR-312', 'ERROR-75'],
      errorCode = get(error, 'response.data.error_code')

    if (filteredErrorCodes.includes(errorCode)) {
      try {
        postErrorInfo({ description: errorCode, payload: error?.response?.data })
      } catch (e) {
        console.log('e', e)
      }

      return Promise.resolve(url.includes('id') ? {} : [])
    }

    //TODO: handle 401 errors only when the user is logged in
    // if (get(error, 'response.status') === 401) {
    //   window.alert('Your session has expired. Please log in again.')
    //   window.location.reload()
    // }

    if (get(singleInstanceRequests, `${url}.abortSignal`)) {
      if (error.name !== 'AbortError') {
        delete singleInstanceRequests[url].abortSignal
      }
    }

    if (contentType?.includes('blob')) {
      try {
        const reader = new FileReader(),
          readBlobAsText = new Promise((resolve, reject) => {
            reader.onload = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsText(error.response.data)
          }),
          textResponse = await readBlobAsText

        jsonResponse = JSON.parse(textResponse)
        error.response.data = jsonResponse
      } catch (err) {
        console.error('Error parsing blob response to JSON:', err)
      }
    } else {
      jsonResponse = error?.response?.data
    }

    let message = error?.config?.customErrorExtractor
      ? error.config.customErrorExtractor(error?.response)
      : get(jsonResponse, 'message', get(jsonResponse, 'error.message', 'An error occurred during the request processing. Please try again.'))
    const disableNotification = error?.config?.disableErrorNotification

    if (error.name !== 'CanceledError' && !disableNotification && error.response?.data?.id) {
      toaster.error(message, error)
    }
    if (!error.response?.data?.id) {
      logEvent.error({
        message: 'Found error with undefined id',
        metadata: {
          error
        }
      })
    }

    if (error.name === 'CanceledError') {
      return Promise.resolve(url.includes('id') ? {} : [])
    }

    return Promise.reject(error.response)
  }
)

export default {
  getIpInfo,
  authenticate,
  postErrorInfo,
  callTestAIApi,
  setHeader(name, value) {
    apiRequest.defaults.headers[name] = value
  },
  setProgressBarVisible(visible) {
    let styleElement = document.getElementById('axios-progress-bar-style')

    if (visible && styleElement) {
      styleElement.parentNode.removeChild(styleElement)
    }

    if (!visible && !styleElement) {
      styleElement = document.createElement('style')
      styleElement.id = 'axios-progress-bar-style'
      styleElement.innerHTML = `
        #nprogress {
          display: none !important;
        }
      `
      document.head.appendChild(styleElement)
    }
  },
  async syncCouchDB(venueId) {
    let result = await apiRequest.post('queues/sync', {
      type: 'refresh_firestore',
      venue_id: venueId,
      only_couch_db: true
    })
    return result
  },
  async cloneVenueToEnvironment(venueId, environment) {
    let result = await apiRequest.post('venues/migrate-data', {
      venue_id: venueId,
      destination: environment
    })
    return result
  },
  async emailVerified(uid) {
    let result = await apiRequest.get(`user-verified?uid=${uid}`).catch(() => false)

    return result
  },
  async loginDemo() {
    let result = await apiRequest.get('demo/token')

    apiRequest.defaults.headers.Authorization = result.token

    return result
  },
  async config() {
    let result = await apiRequest.get('config')
    result.venue_id = result.venue_id || venueIdFromUrl() || localStorage.getItem('X-venue_id') || ''

    return result
  },
  async couchBaseStats(venueId) {
    let result = await apiRequest.get('couchbase/count', { params: { venue_id: venueId } })

    //cleanup
    result = result
      .filter(s => s.channel?.includes('sales') || s.channel?.includes('core') || s.channel?.includes('invoices') || s.channel?.includes('inventories'))
      .map(c => ({ ...c, channel: c.channel.substr(c.channel.lastIndexOf('_')).replace('_', '') }))
    return result
  },
  async couchBaseResync(venueId, increaseVersion) {
    let result = await apiRequest.post('resync-data', {
      venue: {
        type: 'venue_id',
        value: venueId
      },
      dataset: {
        type: 'all'
      },
      cleanup_first: true,
      increase_version: increaseVersion
    })

    return result
  },
  async resyncAreas() {
    let result = await apiRequest.post('/locations/resync-couchbase')

    return result
  },
  setGetChanges,
  async wiskGetChanges({ venueId, sequenceId }) {
    let result = await apiRequest.get('/realtime-sync/changes', {
      progressBar: false,
      params: {
        venue_id: venueId,
        sequence_id: sequenceId,
        client: 'web'
      }
    })

    return result
  },
  async wiskSyncChanges(venueId, sequenceIds) {
    let result = await apiRequest.post(
      '/realtime-sync/get-missing',
      {
        sequence_ids: sequenceIds,
        client: 'web'
      },
      {
        progressBar: false,
        params: {
          venue_id: venueId
        }
      }
    )

    return result
  },
  async wiskChangesCount(venueId, sequenceId) {
    let result = await apiRequest.get('/realtime-sync/count', {
      progressBar: false,
      params: {
        venue_id: venueId,
        sequence_id: sequenceId,
        client: 'web'
      }
    })

    return result
  },
  async user(id) {
    let result = await apiRequest.get(`users/id/${id}`)

    return result
  },
  async heartbeat(duration) {
    let result = await apiRequest.post('heartbeats/register', {}, { params: { duration } })
    return result
  },
  async users(venueId) {
    let result = await apiRequest.get('users', { params: { venue_id: venueId } })

    return result
  },
  async organizations() {
    let result = await apiRequest.get('organizations')

    return result
  },
  async organizationDetails(id) {
    let result = await apiRequest.get(`organizations/id/${id}`)

    return result
  },
  async updateOrganization(id, payload) {
    let operations = {
      operations: Array.isArray(payload) ? payload : [payload]
    }
    let result = await apiRequest.post(`organizations/id/${id}`, operations)
    return result
  },
  async glAccounts() {
    let result = await apiRequest.get('gl-accounts')

    return result
  },
  async updateGLAccount(id, payload) {
    let operations = {
      operations: Array.isArray(payload) ? payload : [payload]
    }
    let result = await apiRequest.post(`gl-accounts/id/${id}`, operations)
    return result
  },
  async applyGLAccount() {
    return apiRequest.post('movements/assign-gl-accounts')
  },
  async updateTax(id, payload) {
    let operations = {
      operations: Array.isArray(payload) ? payload : [payload]
    }
    let result = await apiRequest.post(`tax-rates/id/${id}`, operations)
    return result
  },
  async accountingDistributors() {
    let result = await apiRequest.get('accounting/distributors')

    return result
  },
  async accountingAccounts() {
    let result = await apiRequest.get('accounting/accounts')

    return result
  },
  async accountingTaxes() {
    let result = await apiRequest.get('accounting/tax-rates')

    return result
  },
  async updateUser(id, payload, skipExtra) {
    let operations = {
        operations: Array.isArray(payload) ? payload : [payload]
      },
      url = `users/id/${id}`
    if (skipExtra) {
      url += '?skip_extra=true'
    }
    //returnFullResponse is needed in order to get access to the headers for the error reporting
    let result = await apiRequest.post(url, operations, { returnFullResponse: true }),
      user = result.data

    if (!user?.role) {
      postErrorInfo({ description: 'User role not found on returned user object', payload: { user, url, operations, requestId: result.config.headers['X-Request-Id'] } })
    }
    return user
  },
  async usersVenues({ venueId = null, query = '' }) {
    let result = await apiRequest.get('users-venues', { params: { venue_id: venueId, query } })

    return result
  },
  async updateUserVenue(id, payload) {
    let operations = {
      operations: Array.isArray(payload) ? payload : [payload]
    }
    let result = await apiRequest.post(`users-venues/id/${id}`, operations)

    return result
  },
  updateUserVenueNotification(payload) {
    return apiRequest.post('batch/users-venues', payload)
  },
  async inviteUsers(invitations) {
    let result = await apiRequest.post('invitations', { invitations })
    return result
  },
  async inviteInfo(invitation) {
    let result = await apiRequest.get(`invitation/${invitation}`)

    return result
  },
  async lightspeedAuth(params) {
    let result = await apiRequest.get('lightspeed/pos-integration/oauth2', { params })
    return result
  },
  async lightspeedKSeries(params) {
    let result = await apiRequest.get('lightspeed-kseries/pos-integration/oauth2', { params })
    return result
  },
  async kountaAuth(params) {
    let result = await apiRequest.get('kounta/pos-integration/oauth2', { params })
    return result
  },
  async cloverAuth(params) {
    let result = await apiRequest.get('clover/pos-integration/oauth2', { params })
    return result
  },
  async squareAuth(params) {
    let result = await apiRequest.get('square/pos-integration/oauth2', { params })
    return result
  },
  async quickbooksAuth() {
    let result = await apiRequest.get('public/signup/quickbooks')
    return result
  },
  async posRetry(venueId) {
    let result = await apiRequest.post(`venues/retry-pos?venue_id=${venueId}`)
    return result
  },
  async dashboardOverview(inventoryId, token) {
    let result = await apiRequest.get('dashboard/overview', {
      params: { inventory_id: inventoryId },
      headers: {
        Authorization: token ?? undefined
      }
    })
    return result
  },
  async dashboardHistorical(inventoryId, token) {
    let result = await apiRequest.get('dashboard/historical', {
      params: { inventory_id: inventoryId },
      headers: {
        Authorization: token ?? undefined
      }
    })
    return result
  },
  async dashboardCharts(inventoryId, token) {
    let result = await apiRequest.get('dashboard/overview/highchart', {
      params: { inventory_id: inventoryId },
      headers: {
        Authorization: token ?? undefined
      }
    })
    return result
  },
  async dashboardInventoriesDuration(inventoryId, token) {
    let result = await apiRequest.get('dashboard/inventories/duration', {
      params: { inventory_id: inventoryId },
      headers: {
        Authorization: token ?? undefined
      }
    })
    return result
  },
  dashboardRecalculate(payload) {
    return apiRequest.post('dashboard/recalculate', payload).then(response => {
      toaster.success(translations.txtDashboardRecalculating)
      return response
    })
  },
  async stockPrediction() {
    const result = await apiRequest.get('dashboard/stock-prediction')
    return result
  },
  async POSBrands() {
    let result = await apiRequest.get('pos-brands')
    return result
  },
  async updatePOSBrand(id, payload) {
    let operations = {
      operations: Array.isArray(payload) ? payload : [payload]
    }
    let result = await apiRequest.post(`pos-brands/id/${id}`, operations)
    return result
  },
  async posVeloceApiLocations() {
    let result = await apiRequest.post('pos-integrations/veloce-api/locations', {})
    return result
  },
  async posVeloceApiLocationDivisions(payload) {
    let result = await apiRequest.post('pos-integrations/veloce-api/big-divisions', payload)
    return result
  },
  async posCloverApiLocations(payload) {
    let result = await apiRequest.post('pos-integrations/clover/modifier-groups', payload)
    return result
  },
  async posSquareLocations(payload) {
    let result = await apiRequest.post('/pos-integrations/square/locations', payload)
    return result
  },
  async posRPowerStores() {
    let result = await apiRequest.get('/pos-integrations/rpower/stores')
    return result
  },
  async updatePosIntegration(id, payload) {
    let result = await apiRequest.post(`/pos-integrations/id/${id}`, payload)
    return result
  },
  async clearPreviousDataByPOSIntegration(id) {
    let result = await apiRequest.post(`/pos-integrations/reset-pos-data/id/${id}`, {})
    return result
  },
  async priceChanges(payload) {
    let result = await apiRequest.post('prices/report', payload)
    return result
  },
  async movements() {
    let result = await apiRequest.get('movements/high-level')

    return result
  },
  async updateMovementsBatch(payload) {
    let result = await apiRequest.post('batch/movements', payload)
    return result
  },
  movementsQuickbooksDesktop: payload =>
    new Promise((resolve, reject) => {
      let batch = Array.isArray(payload),
        movementIds = batch ? payload : [payload],
        config = {
          method: 'post',
          responseType: 'blob',
          url: 'movements/quickbooks-desktop',
          data: { movement_ids: movementIds }
        }

      if (!batch) {
        config.customErrorExtractor = error => error?.data?.failed_movements[0]?.reason
      }

      apiRequest(config)
        .then(response => {
          promptDownload({ blob: response.data, fileName: sanitize(response.headers.filename || `QuickbooksDesktopExport_${new Date().toISOString()}.iif`) })
          resolve()
        })
        .catch(error => {
          if (!batch && error?.data) {
            error.data.message = error?.data?.failed_movements[0]?.reason
          }
          reject(error)
        })
    }),
  async ocrResultDraftInvoice(id) {
    let result = await apiRequest.get(`draft-invoices/ocr-result/${id}`)

    return result
  },
  async draftInvoices() {
    let result = await apiRequest.get('draft-invoices')

    return result
  },
  async getDraftInvoice(id) {
    let result = await apiRequest.get(`draft-invoices/id/${id}`)

    return result
  },
  getLastDraftInvoiceByWiskDistributor(params) {
    return apiRequest.get('draft-invoices/last-by-wisk-distributor', { params })
  },
  async updateDraftInvoice(id, payload) {
    let operations = {
      operations: Array.isArray(payload) ? payload : [payload]
    }
    let result = await apiRequest.post(`draft-invoices/id/${id}`, operations)
    return result
  },
  async updateDraftInvoiceLine(draftInvoiceId, id, payload) {
    let operations = {
      operations: Array.isArray(payload) ? payload : [payload]
    }
    let result = await apiRequest.post(`draft-invoices/id/${draftInvoiceId}/lines/${id}`, operations)
    return result
  },
  calculateDraftInvoiceLineTotal(draftInvoiceId, id, payload) {
    return apiRequest.post(`/draft-invoices/id/${draftInvoiceId}/lines/${id}/calculate`, { operations: payload }, { disableSuccessNotification: true, progressBar: false })
  },
  draftInvoicePing(id) {
    return apiRequest.post(`/draft-invoices/id/${id}/access`, {}, { disableSuccessNotification: true, progressBar: false })
  },
  draftInvoiceLeave(id) {
    return apiRequest.post(`/draft-invoices/id/${id}/leave`, {}, { disableSuccessNotification: true, progressBar: false })
  },
  async updateDraftInvoiceExtraLine(draftInvoiceId, id, payload) {
    let operations = {
      operations: Array.isArray(payload) ? payload : [payload]
    }
    let result = await apiRequest.post(`draft-invoices/id/${draftInvoiceId}/extra-lines/${id}`, operations)
    return result
  },
  async setDraftInvoiceItemsOrder(payload) {
    let result = await apiRequest.post('draft-invoices/reorder', payload)
    return result
  },
  async reimportDraftInvoices(ids) {
    let result = await apiRequest.post('draft-invoices/re-import', {
      draft_invoice_ids: ids
    })
    return result
  },
  async draftInvoiceFilesManage(payload) {
    let result = await apiRequest.post('draft-invoices/manage-files', payload)
    return result
  },
  async costChanges(payload, token, vid) {
    let result = await apiRequest.post('movements/cost-changes', payload, {
      headers: {
        Authorization: token ?? undefined,
        'X-venue_id': vid || apiRequest.defaults.headers['X-venue_id']
      }
    })
    return result
  },
  async movementCostChanges(movementId) {
    let result = await apiRequest.get(`movements/id/${movementId}/cost-changes`)
    return result
  },
  async sendVenueWeeklyReport() {
    let result = await apiRequest.post('summary-report/send-weekly-test', {})
    return result
  },
  async sendVenueDailyReport() {
    let result = await apiRequest.post('summary-report/send-daily-test', {})
    return result
  },
  async movementsFlat(payload) {
    let result = await apiRequest.post('movements/flatten-all', payload)

    return result
  },
  async movementsFlatByAttributes(payload) {
    let result = await apiRequest.post('movements/flatten-by-attributes', payload),
      mapped = result
        .map(r => ({
          ...arrayToObjectById(r.attributes, 'type'),
          aggregated_metrics: { ...r.aggregated_metrics },
          id: guid()
        }))
        // eslint-disable-next-line no-prototype-builtins
        .map(item => Object.entries(item).reduce((acc, [key, val]) => ({ ...acc, [key]: val.hasOwnProperty('value') ? val.value : val }), {}))

    return mapped
  },
  async movementById(id) {
    let result = await apiRequest.get(`movements/id/${id}`)
    return result
  },
  async updateMovement(id, payload) {
    let operations = {
      operations: Array.isArray(payload) ? payload : [payload]
    }
    let result = await apiRequest.post(`movements/id/${id}`, operations)
    return result
  },
  async movementExtraLineReasons() {
    let result = await apiRequest.get('movement-extra-line-reasons')
    return result
  },
  async setMovementExtraLineReason(id, payload) {
    let operations = {
      operations: Array.isArray(payload) ? payload : [payload]
    }
    let result = await apiRequest.post(`movement-extra-line-reasons/id/${id}`, operations)
    return result
  },
  async updateMovementAdjustmentReason(id, payload) {
    let operations = {
      operations: Array.isArray(payload) ? payload : [payload]
    }
    let result = await apiRequest.post(`movement-adjustment-reasons/id/${id}`, operations)
    return result
  },
  async intakeFromOrder(orderId, payload) {
    let result = await apiRequest.post(`purchase-orders/${orderId}/to/intake`, payload)
    return result
  },
  async updateWebView(viewId, operation, venueId) {
    let payload = { operation }

    if (venueId) {
      payload.venue_id = venueId
    }
    let result = await apiRequest.post(`web-view-settings/id/${viewId}`, payload)
    return result
  },
  async setWebViewsOrder(payload) {
    let result = await apiRequest.post('web-view-settings/reorder', payload)
    return result
  },
  async updateWebViewSystem(viewId, operation) {
    await setTimeoutAsync(0)

    console.error('trying to save system view but we do nott do that anymore!!!!', viewId, operation)
    return null
  },
  async setWebViewsSystemOrder(payload) {
    let result = await apiRequest.post('web-view-settings/system/reorder', payload)
    return result
  },
  async emailSupport(payload) {
    let result = await apiRequest.post('intercom/send-message', payload)
    return result
  },
  async emailSupportPublic(payload) {
    let result = await apiRequest.post('public/contact/send-message', payload)
    return result
  },
  async lightspeedSignupPublic(params) {
    let result = await apiRequest.get('public/signup/lightspeed', { params })
    return result
  },
  async publicRedirect(api, params) {
    let result = await apiRequest.get(`public/redirect/${api}`, { params })
    return result
  },
  async updateRole(id, payload) {
    let operations = {
      operations: Array.isArray(payload) ? payload : [payload]
    }
    let result = await apiRequest.post(`roles/id/${id}`, operations)
    return result
  },
  async rolesForVenue(venueId) {
    let result = await apiRequest.get('roles', { params: { venue_id: venueId } })

    return result
  },
  async updateAllergen(id, payload) {
    let operations = {
      operations: Array.isArray(payload) ? payload : [payload]
    }
    let result = await apiRequest.post(`allergens/id/${id}`, operations)
    return result
  },
  async venueTransfersReport() {
    let result = await apiRequest.get('transfer-requests/in-preparation')

    return result
  },
  async updateVenueTransfer(id, payload) {
    let operations = {
      operations: Array.isArray(payload) ? payload : [payload]
    }
    let result = await apiRequest.post(`transfer-requests/id/${id}`, operations)
    return result
  },
  async venuePlans(params = {}) {
    let result = await apiRequest.get('public/payments/plans/v2', { params })

    return result
  },
  async resetPassword(email) {
    let result = await apiRequest.post('public/reset-password', { email })
    return result
  },
  async signupSources() {
    let result = await apiRequest.get('public/signup/sources')
    return result
  },
  async updateVenue(id, payload) {
    let operations = {
      operations: Array.isArray(payload) ? payload : [payload]
    }
    let result = await apiRequest.post(`venues/id/${id}`, operations)
    return result
  },
  async bottleTitleSearch({ title, itemCode, distributorId = '', skipArchived = false, skipCurrentVenueItems = false }) {
    let payload = { distributor_id: distributorId, limit: 100, skip_archived: skipArchived, skip_current_venue_items: skipCurrentVenueItems, version: 2, anonymize_results: false, title, item_code: itemCode },
      result = await apiRequest.post('items/find', payload)

    return result
  },
  async bottleImport(payload) {
    let result = await apiRequest.post('items/import', payload)
    return result
  },
  async bottleMergePreview(payload) {
    let result = await apiRequest.post('bottles/merge/preview', payload)
    return result
  },
  async bottleMerge(payload) {
    let result = await apiRequest.post('bottles/merge/operations', payload)
    return result
  },
  async bottleUnMerge(payload) {
    let result = await apiRequest.post('bottles/unmerge', payload)
    return result
  },
  async variance(payload) {
    let result = await apiRequest.post('variances/inventories', payload)
    return result
  },
  async varianceIndependentInventory(payload) {
    let result = await apiRequest.post('variances/independent-inventories', payload)
    return result
  },
  async varianceGrops() {
    let result = await apiRequest.get('variance-groups')
    return result
  },
  async updateVarianceGroup(id, payload) {
    let operations = {
      operations: Array.isArray(payload) ? payload : [payload]
    }
    let result = await apiRequest.post(`variance-groups/id/${id}`, operations)
    return result
  },
  async inventoryFlat(id, payload) {
    let result = await apiRequest.post(`inventories/id/${id}/flatten`, payload)
    return result
  },
  async inventory(id) {
    let result = await apiRequest.get(`inventories/id/${id}`)
    return result
  },
  async inventoriesHighLevel({ count, exclude_archived = false, token }) {
    let result = await apiRequest.get('inventories/high-level', {
      params: { count, exclude_archived },
      headers: {
        Authorization: token ?? undefined
      }
    })
    return result
  },
  async startInventory(payload) {
    let result = await apiRequest.post('inventories/start', payload, { disableSuccessNotification: true })
    return result
  },
  async updateInventory(id, payload) {
    let operations = {
      operations: Array.isArray(payload) ? payload : [payload]
    }
    let result = await apiRequest.post(`inventories/id/${id}`, operations)
    return result
  },
  //TODO: choose a format for when we have 2 ids
  async updateInventoryLocation(id, { locationId, operation }) {
    let data = {
      operations: Array.isArray(operation) ? operation : [operation]
    }
    let result = await apiRequest.post(`inventories/id/${id}/locations/${locationId}`, data)
    return result
  },
  async moveMeasurementsAreas(payload) {
    let result = await apiRequest.post('inventories/move-measurements-between-areas', payload)
    return result
  },
  async independentInventoryGroups() {
    let result = await apiRequest.get('independent-inventories/groups')
    return result
  },
  async updateIndependentInventoryGroup(id, payload) {
    let operations = {
      operations: Array.isArray(payload) ? payload : [payload]
    }
    let result = await apiRequest.post(`independent-inventories/groups/id/${id}`, operations)
    return result
  },
  async independentInventoriesForGroup(groupId) {
    let result = await apiRequest.post('independent-inventories/high-level', { group_id: groupId, include_archived: true })
    return result
  },
  async updateIndependentInventory(id, payload) {
    let operations = {
      operations: Array.isArray(payload) ? payload : [payload]
    }
    let result = await apiRequest.post(`independent-inventories/id/${id}`, operations)
    return result
  },
  async independentInventory(id) {
    let result = await apiRequest.get(`independent-inventories/id/${id}/get`) //the /get at the end is only temporary until the api is fixed
    return result
  },
  async consumption(payload) {
    let result = await apiRequest.post('consumptions/totals/flatten', payload)
    result = result.map(r => ({ ...r, quantity: isFinite(r.quantity) ? r.quantity : r.consumption }))
    return result
  },
  async consumptionByCategories(payload) {
    let result = await apiRequest.post('consumptions/categories', payload)
    return result
  },
  async emailConsumptionReportsForAllVenues(payload) {
    let result = await apiRequest.post('consumptions/send-weekly-brand-overall-reports', payload)
    return result
  },
  async varianceDetails(payload) {
    let result = await apiRequest.post('variance/item/details', payload)
    return result
  },
  async varianceUnmapped(payload) {
    let result = await apiRequest.post('sales/unmapped', payload)
    return result
  },
  async venuesHighLevel() {
    let result = await apiRequest.get('venues/high-level')

    return result
  },
  async venues() {
    let result = await apiRequest.get('venues')

    return result
  },
  async venueById(id) {
    let result = await apiRequest.get(`venues/id/${id}`)

    return result
  },
  async venueStats(id) {
    let result = await apiRequest.get(`venues/id/${id}/stats`)

    return result
  },
  async venueCheckGooglePlaceUsed(placeId) {
    let response = await apiRequest.get(`venues/google-place-id/${placeId}/exists`)

    return response.result || false
  },
  async itemStats(itemId) {
    let result = await apiRequest.get(`bottles/${itemId}/stats`)
    return result
  },
  async itemInventoryHistory(itemId) {
    //TODO: the api address should be bottles not bottle
    let result = await apiRequest.get(`bottle/${itemId}/evolution`)
    return result
  },
  async timeline({ type, id }) {
    let result = await apiRequest.get(`timeline?type=${type}&id=${id}`)
    return result
  },
  async getFilteredTimeline(payload) {
    let result = await apiRequest.post('timeline/search', payload)
    return result
  },
  async getShoppingCart() {
    let result = await apiRequest.get('shopping-cart')
    return result
  },
  async addToShoppingCart(operationsList) {
    let result = await apiRequest.post('shopping-cart', {
      operations: [operationsList]
    })
    return result.filter(item => item.units)
  },
  async getDistributorPrices() {
    let result = await apiRequest.get('item-distributors/prices')
    return result
  },
  async checkoutDistributors(distributorsList) {
    let result = await apiRequest.post('shopping-cart/checkout/distributors', {
      distributor_ids: distributorsList
    })
    return result
  },
  async prefillDistributor(payload) {
    let result = await apiRequest.post('shopping-cart/prefill/distributor', payload)
    return result
  },
  async orderFromSAQ(purchaseOrderId) {
    let result = await apiRequest.get(`purchase-orders/saq/${purchaseOrderId}`)
    return result
  },
  async getPurchaseOrderPublic(id, phone) {
    let result = await apiRequest.get(`public/purchase-orders/uuid/${id}`, { params: { phone } })

    return result
  },
  async getPurchaseOrders() {
    let result = await apiRequest.get('purchase-orders/high-level')

    return result
  },
  async getPurchaseOrder(id) {
    let result = await apiRequest.get(`purchase-orders/id/${id}`)

    return result
  },
  async updatePurchaseOrder(id, payload) {
    let operations = {
      operations: Array.isArray(payload) ? payload : [payload]
    }

    let result = await apiRequest.post(`purchase-orders/id/${id}`, operations)
    return result
  },
  async updatePurchaseOrdersBatch(payload) {
    let result = await apiRequest.post('batch/purchase-orders', payload)
    return result
  },
  async clearShoppingCart(payload) {
    let result = null
    if (Array.isArray(payload)) {
      result = await apiRequest.post('shopping-cart/clear/distributors', {
        distributor_ids: payload
      })
    }

    return result.shopping_cart || result
  },
  async clearShoppingCartAll() {
    let result = null
    result = await apiRequest.post('shopping-cart/clear/all')

    return result.shopping_cart || result
  },
  async getBrandS() {
    let result = await apiRequest.get('foodtastic/brands')
    return result
  },
  async getBrandReport(brandId) {
    let result = await apiRequest.post('foodtastic/report/all', { type: 'week' }, { params: { brand_id: brandId } })
    return result
  },
  async updateSubrecipe(id, payload) {
    let operations = {
        operations: Array.isArray(payload) ? payload : [payload]
      },
      result = await apiRequest.post(`subrecipes/id/${id}`, operations)

    return result
  },
  async copySubrecipes(venueId, payload) {
    //why add venue id to url when we have it already in header?
    let result = await apiRequest.post(`subrecipes/copy/${venueId}`, payload)

    return result
  },
  async copyDataToVenue(payload) {
    let result = await apiRequest.post('/venues/copy-data', payload)

    return result
  },
  async getSaleCategories() {
    let result = await apiRequest.get('pos-sale-categories')
    return result
  },
  async updateSaleCategory(id, payload) {
    let result = await apiRequest.post(`pos-sale-categories/id/${id}`, payload)
    return result
  },
  async getMenuCategories() {
    let result = await apiRequest.get('pos-menu-categories')
    return result
  },
  async updateMenuCategory(id, payload) {
    let result = await apiRequest.post(`pos-menu-categories/id/${id}`, payload)
    return result
  },
  async getPOSItemsExtraData(params) {
    let result = await apiRequest.get('pos-items/extra-data', { params })
    return result
  },
  async updatePOSItem(id, payload) {
    let operations = {
        operations: Array.isArray(payload) ? payload : [payload]
      },
      result = await apiRequest.post(`pos-items/id/${id}`, operations)

    return result
  },
  async clonePOSItemAsDraft(id) {
    let result = await apiRequest.post(`pos-items/id/${id}/clone-draft`, {})

    return result
  },
  async getPOSItemHistory(id) {
    let result = await apiRequest.get(`/pos-items/id/${id}/history`)
    return result
  },
  async cleanUnusedPOSItemsPreview() {
    let result = await apiRequest.get('pos-items/clear-unused-preview', {})
    return result
  },
  cleanUnusedPOSItemsConfirm() {
    return apiRequest.post('pos-items/clear-unused-confirm', {}).then(response => {
      toaster.success(translations.txtGenericOperationSuccess)
      return response
    })
  },
  async salesForPOSItem(payload) {
    let result = await apiRequest.post('sales', payload)

    return result
  },
  async updatePOSModifier(id, payload) {
    let operations = {
        operations: Array.isArray(payload) ? payload : [payload]
      },
      result = await apiRequest.post(`pos-modifiers/id/${id}`, operations)

    return result
  },
  async salesTotals(payload) {
    let result = await apiRequest.post('sales/totals', payload)

    return result
  },
  async salesByItem(payload) {
    let result = await apiRequest.post('sales/items/flatten', payload)

    return result
  },
  async salesPerpetual(itemId) {
    let result = await apiRequest.post('sales/item/perpetual', { item_id: itemId })

    return result
  },
  async salesImport(posIntegrationId, since) {
    let result = since ? await apiRequest.post(`pos-integrations/re-import/id/${posIntegrationId}`, { since }) : await apiRequest.post(`pos-integrations/async-import/id/${posIntegrationId}`, {})

    return result
  },
  async updateServingSize(id, payload) {
    let operations = {
        operations: Array.isArray(payload) ? payload : [payload]
      },
      result = await apiRequest.post(`serving-sizes/id/${id}`, operations)

    return result
  },
  async setServingSizesOrder(payload) {
    let result = await apiRequest.post('serving-sizes/reorder', payload)
    return result
  },
  async updatePOSItemsBatch(payload) {
    let result = await apiRequest.post('batch/pos-items', payload)
    return result
  },
  async swapItem(payload) {
    let result = await apiRequest.post('items/swap', payload)
    return result
  },
  async updateBottle(bottleId, payload) {
    let operations = {
        operations: Array.isArray(payload) ? payload : [payload]
      },
      result = await apiRequest.post(`bottles/id/${bottleId}`, operations)

    return result
  },
  async cloneItem(itemId) {
    let result = await apiRequest.post(`bottles/id/${itemId}/clone`)

    return result
  },
  async saveItemVariant(id, payload) {
    if (isFinite(id)) {
      let operations = {
          operations: Array.isArray(payload) ? payload : [payload]
        },
        result = await apiRequest.post(`item-distributors/id/${id}`, operations)

      return result
    }

    return Promise.reject(new Error(`Invalid ID: ${id}`))
  },
  async cloneItemVariant(id) {
    let result = await apiRequest.post(`item-distributors/id/${id}/clone`)

    return result
  },
  async clonePurchaseOrder(id) {
    let result = await apiRequest.post(`purchase-orders/id/${id}/clone`)

    return result
  },
  async cloneMovement(id) {
    let result = await apiRequest.post(`movements/id/${id}/clone`)

    return result
  },
  async updateBottlesBatch(payload) {
    let result = await apiRequest.post('batch/bottles', payload),
      failed = (result && result.not_updated_venue_bottles) || []
    return failed
  },
  async updateDistributor(distributorId, payload) {
    let operations = {
        operations: Array.isArray(payload) ? payload : [payload]
      },
      result = await apiRequest.post(`distributors/id/${distributorId}`, operations)
    return result
  },
  async getDistributor(id) {
    let result = await apiRequest.get(`distributors/id/${id}`)

    return result
  },
  async updateWiskDistributor(id, payload) {
    let operations = {
      operations: Array.isArray(payload) ? payload : [payload]
    }
    let result = await apiRequest.post(`wisk-distributors/id/${id}`, operations)
    return result
  },
  async wiskDistributors(id) {
    let result = await apiRequest.get('wisk-distributors' + (id ? `/id/${id}` : ''))

    return result
  },
  async wiskDistributorsSearch(payload) {
    let result = await apiRequest.post('wisk-distributors/find', payload)

    return result
  },
  async wiskDistributorsMerge(payload) {
    let result = await apiRequest.post('wisk-distributors/merge', payload)

    return result
  },
  async updateCustomField(id, payload) {
    let operations = {
        operations: Array.isArray(payload) ? payload : [payload]
      },
      result = await apiRequest.post(`custom-fields/id/${id}`, operations)
    return result
  },
  async updateLocation(id, payload) {
    let operations = {
        operations: Array.isArray(payload) ? payload : [payload]
      },
      result = await apiRequest.post(`locations/id/${id}`, operations)
    return result
  },
  async setLocationsOrder(payload) {
    let result = await apiRequest.post('locations/reorder', payload)
    return result
  },
  async updateCategory(id, payload) {
    let operations = {
        operations: Array.isArray(payload) ? payload : [payload]
      },
      result = await apiRequest.post(`categories/id/${id}`, operations)
    return result
  },
  async posItemsIngredientsCopy(options) {
    let result = await apiRequest.post('pos-items/copy-ingredients-from-pos-items-with-same-name', options)

    return result
  },
  async updateFamily(id, payload) {
    let operations = {
        operations: Array.isArray(payload) ? payload : [payload]
      },
      result = await apiRequest.post(`families/id/${id}`, operations)
    return result
  },
  async venueLogin(venueId) {
    apiRequest.defaults.headers['X-venue_id'] = venueId
    localStorage.setItem('X-venue_id', venueId)
    venueIdToUrl(venueId)
  },
  async stripeDashboard() {
    let result = await apiRequest.get('stripe/customer')

    return result
  },
  async removeCovidCoupon() {
    let result = await apiRequest.post('stripe/subscription/remove-coupon')

    return result
  },
  async postCovidCoupon() {
    let result = await apiRequest.post('stripe/subscription/apply-post-covid-coupon')

    return result
  },
  async stripeInvoices() {
    let result = await apiRequest.get('stripe/customer/invoices')

    return result
  },
  async stripeCharges() {
    let result = await apiRequest.get('stripe/customer/charges')

    return result
  },
  async stripeRetry(invoiceId = '') {
    let result = await apiRequest.post(`stripe/customer/charges/retry/${invoiceId}`)

    return result
  },
  async saveStripeCustomer(payload) {
    let operations = {
        operations: Array.isArray(payload) ? payload : [payload]
      },
      result = await apiRequest.post('stripe/customer/', operations)
    return result
  },
  async saveStripeSubscription(payload) {
    let operations = {
        operations: Array.isArray(payload) ? payload : [payload]
      },
      result = await apiRequest.post('stripe/subscription', operations)
    return result
  },
  async stripeCard(id, payload) {
    let operations = {
        operations: Array.isArray(payload) ? payload : [payload]
      },
      result = await apiRequest.post(`stripe/customer/card/${id}`, operations)
    return result
  },
  async anotherCard(token) {
    let result = await apiRequest.post('payments', { payment_token: token })
    return result
  },
  async searchGooglePlaces(query, sessionToken, types = 'establishment') {
    let result = await apiRequest.get(`public/googleplaces/autocomplete?input=${query}&key=AIzaSyBKrmdLjSUDob0EKctzBt2YchrvIdpRWlk&sessiontoken=${sessionToken}&types=${types}`)
    return (result && result.predictions) || []
  },
  async searchGoogleImages(query = '', skip = 0, count = 10, cx = '4be1b72d589ba3545') {
    let result = await apiRequest.get(`public/googlesearch/search?q=${query}&num=${count}&start=${skip + 1}&searchType=image&key=AIzaSyAa11lXYfUjFVgELPz-zgx2grQu1J6wSKU&cx=${cx}&safe=high`)
    result.items = result.items || []
    result.items = result.items.filter(r => !!r)
    return result
  },
  async detailsGooglePlaces(placeId) {
    let response = await apiRequest.get(`public/googleplaces/place/details?placeid=${placeId}&key=AIzaSyBKrmdLjSUDob0EKctzBt2YchrvIdpRWlk`)
    return response && response.result
  },
  emailPdf(url, payload = {}) {
    return apiRequest.post(`/reports/${url}`, payload).then(response => {
      toaster.success(translations.txtGenericEmailInProgress)
      watchReportRecursive(response.id, this)
      return response
    })
  },
  regenerateReport(id, payload = {}) {
    return apiRequest.get(`/reports/id/${id}/retry`, payload).then(response => {
      toaster.success(translations.txtGenericEmailInProgress)
      watchReportRecursive(id, this)
      return response
    })
  },
  async requestedReports() {
    let response = await apiRequest.get('/reports/all')
    return response
  },
  async reportById(id) {
    let response = await apiRequest.get(`/reports/id/${id}`)
    return response
  },
  async markDownloadedReport(id) {
    let response = await apiRequest.patch(`/reports/id/${id}/downloaded`)
    return response
  },
  uploadImage(data) {
    return new Promise((resolve, reject) => {
      apiRequest({
        method: 'post',
        headers: { 'Content-Type': 'multipart/form-data' },
        url: 'image/create',
        data
      })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  uploadSales(data) {
    return new Promise((resolve, reject) => {
      apiRequest({
        method: 'post',
        headers: { 'Content-Type': 'multipart/form-data' },
        url: 'pos-integrations/upload',
        data
      })
        .then(response => {
          resolve(response)
          toaster.success(translations.txtGenericOperationSuccess)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  uploadItems(data, mode = 'items') {
    return new Promise((resolve, reject) => {
      apiRequest({
        method: 'post',
        headers: { 'Content-Type': 'multipart/form-data' },
        url: `batch-import/${mode}`,
        data
      })
        .then(response => {
          resolve(response)
          toaster.success(translations.txtGenericOperationSuccess)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  uploadDistributors(data) {
    return new Promise((resolve, reject) => {
      apiRequest({
        method: 'post',
        headers: { 'Content-Type': 'multipart/form-data' },
        url: 'distributors/upload/xls',
        data
      })
        .then(response => {
          resolve(response)
          toaster.success(translations.txtGenericOperationSuccess)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  async uploadMediaURL(url, type) {
    return apiRequest.post('media/create/from/url', { url, type })
  },
  async uploadMedia(formData) {
    return apiRequest.post('media/create', formData)
  },
  /**
   * Downloads a blob and triggers file download to user. Returns a Promise
   * @param {string} url The url to post the call to
   * @param {string} fileName The file name displayed to the user in the download prompt
   * @param {object} data Optional: data to be posted to the server
   * @returns {Promise}
   */
  downloadBlob: (url, fileName = 'file', data = {}) =>
    new Promise((resolve, reject) => {
      apiRequest({
        method: 'post',
        responseType: 'blob',
        url,
        data
      })
        .then(response => {
          promptDownload({ fileName: sanitize(response.headers.filename || fileName), blob: response.data })

          resolve()
        })
        .catch(error => {
          reject(error)
        })
    }),
  downloadBlobBase64: (url, data = {}) =>
    new Promise((resolve, reject) => {
      apiRequest({
        method: 'post',
        responseType: 'blob',
        url,
        data
      })
        .then(response => {
          let blob = response.data,
            reader = new FileReader()

          reader.readAsDataURL(blob)
          reader.onloadend = () => {
            resolve(reader.result)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
}
