import get from 'lodash.get'
import { DateTime } from 'luxon'
import { formatDate, currencyFormatHideZero, round, setupCustomFieldsAsColumns } from '@/modules/utils'
import agGridFilters from '@/modules/agGridFilters'
import cellRenderers from '@/components/cells/cellRenderers'

const convertYearMonthToDate = (year, month) => new Date(parseInt(year, 10), parseInt(month, 10) - 1)

const getGridOptions = () => ({
  rowSelection: {
    mode: 'multiRow',
    checkboxes: true,
    isRowSelectable: node => node.data && !node.data.wiskRowHidden
  }
})

const getGridColumns = ({ translations, save, customFields, distributorsById, venue }) => {
  let translate = translations.translate,
    columns = [
      {
        headerName: translations.txtGenericYear,
        colId: 'year',
        wiskExpandFirst: true,
        enableRowGroup: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
        hide: true,
        keyCreator: params => JSON.stringify({ colId: 'year', sort: params.value, title: params.value, id: params.value }),
        valueGetter: params => (params?.data?.date?.getFullYear && params.data.date.getFullYear()) || ''
      },
      {
        headerName: translations.txtGenericMonth,
        colId: 'month',
        wiskExpandFirst: true,
        enableRowGroup: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
        hide: true,
        valueFormatter: params => params.value.title,
        keyCreator: params => JSON.stringify(params.value),
        valueGetter: params => {
          let month = (params?.data?.date?.getMonth && params.data.date.getMonth()) || 0

          return {
            sort: month + 1,
            colId: 'month',
            title: translations[`txtGenericMonth${month}`],
            id: params?.data?.date && { month, year: params.data.date.getFullYear() }
          }
        }
      },
      {
        headerName: translations.txtGenericWeek,
        colId: 'week',
        wiskExpandFirst: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
        enableRowGroup: true,
        hide: true,
        valueFormatter: params => params.value.title,
        keyCreator: params => JSON.stringify(params.value),
        valueGetter: params => {
          let dt = (params.data && params.data.date && DateTime.fromJSDate(params.data.date)) || null,
            week = (dt && dt.weekNumber) || 0,
            year = (week && params.data.date.getYear()) || 0,
            sort = parseInt(year.toString() + week.toString(), 10),
            title = (dt && `${translations.txtGenericWeek} ${week}, ${translations.txtGenericStartedOn} ${dt.startOf('week').toFormat('DD')}`) || ''

          return { colId: 'week', sort, title }
        }
      },
      {
        headerName: translations.txtGenericDate,
        colId: 'date',
        field: 'date',
        ...agGridFilters.date,
        valueFormatter: params => formatDate(params.value)
      },
      {
        colId: 'movementDistributor',
        headerName: translations.txtGenericDistributor,
        enableRowGroup: true,
        valueGetter: params => get(distributorsById[get(params, 'data.operation.distributor_id')], 'title', '')
      },
      {
        headerName: translations.txtGenericOperation,
        colId: 'operation',
        minWidth: 100,
        width: 100,
        maxWidth: 200,
        filterType: 'list',
        cellRendererParams: {
          getFilterItems: () => [
            { id: translations.groupMovementTypes.intake, title: translations.groupMovementTypes.intake },
            { id: translations.groupMovementTypes.return, title: translations.groupMovementTypes.return },
            { id: translations.groupMovementTypes.transfer_out, title: translations.groupMovementTypes.transfer_out }
          ]
        },
        valueGetter: params => {
          if (params?.data?.operation) {
            return translations.groupMovementTypes[params.data.operation.type] || params.data.operation.type
          }
          return ''
        }
      },
      {
        headerName: translations.txtGenericDescription,
        colId: 'description',
        enableRowGroup: true,
        minWidth: 100,
        width: 400,
        maxWidth: 600,
        ...agGridFilters.text,
        valueGetter: params => (params.data && params.data.description) || ''
      },
      {
        colId: 'sentToAccounting',
        headerName: translations.txtMovementAccountingSent,
        hide: true,
        enableRowGroup: true,
        cellRenderer: 'CellCheckbox',
        remove: !venue?.accounting_enabled,
        keyCreator: params => params.value.input_value,
        cellRendererParams: { readonly: true },
        valueGetter: params => ({ input_value: !!get(params, 'data.sent_to_accounting') })
      },
      {
        colId: 'transferOutSent',
        headerName: translations.txtMovementTransferOutSent,
        hide: true,
        enableRowGroup: true,
        cellRenderer: 'CellCheckbox',
        keyCreator: params => params.value.input_value,
        cellRendererParams: { readonly: true },
        valueGetter: params => ({ input_value: !!get(params, 'data.sent') })
      },
      {
        colId: 'photos',
        headerName: translations.txtGenericPhotos,
        minWidth: 100,
        width: 100,
        cellClass: ['cell-more-details'],
        cellRenderer: 'CellMoreDetails',
        cellRendererParams: {
          icon: 'wisk-show',
          getHideButton: (id, data) => !data.attachments,
          excludeFromExport: true,
          onClick: id => {
            console.log('default more details onClick called, this does nothing; you need to override it from calling component', id)
          }
        },
        valueGetter: params => ({
          id: params.data && params.data.id,
          group: !!params.node.group,
          attachments: get(params, 'data.stats.attachments'),
          input_value: !!get(params, 'data.stats.attachments')
        })
      },
      {
        colId: 'person',
        headerName: translations.txtGenericPerson,
        ...agGridFilters.text,
        enableRowGroup: true,
        valueGetter: params => get(params, 'data.stats.started_by', '')
      },
      {
        colId: 'invoiceNumber',
        hide: true,
        ...agGridFilters.text,
        headerName: translations.txtMovementEditInvoiceNumber,
        valueGetter: params => get(params, 'data.operation.invoice_number', '')
      },
      {
        headerName: translations.txtGenericCreatedAt,
        colId: 'insertDate',
        minWidth: 100,
        valueFormatter: params => formatDate(params.value),
        ...agGridFilters.date,
        valueGetter: params => get(params, 'data.insert_date', '')
      },
      {
        colId: 'invoiceTotal',
        hide: true,
        headerName: translations.txtMovementEditInvoiceTotalPaper,
        cellRenderer: 'CellNumber',
        aggFunc: 'wiskSum',
        cellRendererParams: { decimals: 2, readonly: true, prefix: window.WiskGlobals.currency },
        ...agGridFilters.number,
        cellClass: ['currency'],
        valueFormatter: params => currencyFormatHideZero(params.value),
        valueGetter: params => ({ input_value: get(params, 'data.operation.invoice_total') })
      },
      {
        colId: 'total',
        headerName: translations.txtGenericTotal,
        cellRenderer: 'CellNumber',
        aggFunc: 'wiskSum',
        cellRendererParams: { decimals: 2, readonly: true, prefix: window.WiskGlobals.currency },
        ...agGridFilters.number,
        cellClass: ['currency'],
        valueFormatter: params => currencyFormatHideZero(params.value),
        valueGetter: params => ({ input_value: get(params, 'data.dollars_signed') })
      },
      {
        colId: 'CostChanges',
        headerName: translations.txtCostChangesCount,
        cellRenderer: 'CellNumber',
        cellRendererParams: { readonly: true },
        ...agGridFilters.number,
        valueGetter: params => ({ input_value: get(params, 'data.cost_changes') })
      },
      {
        colId: 'more',
        sortOrder: 40000,
        headerName: translations.columnDetails,
        minWidth: 100,
        width: 100,
        suppressColumnsToolPanel: true,
        suppressSortingToolPanel: true,
        suppressFiltersToolPanel: true,
        cellClass: ['cell-more-details'],
        cellRenderer: 'CellMoreDetails',
        cellRendererParams: {
          onClick: id => {
            console.log('default more details onClick called, this does nothing; you need to override it from calling component', id)
          }
        },
        valueGetter: params => ({
          id: params.data && params.data.id,
          group: !!params.node.group
        })
      }
    ]

  let customFieldsColumns = setupCustomFieldsAsColumns({ customFields, translate, save, agGridFilters, columns })

  customFieldsColumns.forEach(column => {
    columns.push(column)
  })

  return columns
}

const getGridColumnsFlatByItems = ({ translations, openDetails, openPhotos, distributorsById, distributors, venue, movementTypes }) => ({
  year: {
    headerName: translations.txtGenericYear,
    colId: 'year',
    wiskExpandFirst: true,
    enableRowGroup: true,
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
    hide: true,
    sortOrder: 100,
    keyCreator: params => JSON.stringify({ colId: 'year', sort: params.value, title: params.value, id: params.value }),
    valueGetter: params => get(params, 'data.movementInfo.date', new Date()).getFullYear()
  },
  month: {
    headerName: translations.txtGenericMonth,
    colId: 'month',
    wiskExpandFirst: true,
    enableRowGroup: true,
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
    hide: true,
    sortOrder: 200,
    keyCreator: params => JSON.stringify(params.value),
    valueFormatter: params => params.value.title,
    valueGetter: params => {
      let month = get(params, 'data.movementInfo.date', new Date()).getMonth()
      return {
        colId: 'month',
        sort: month + 1,
        title: translations[`txtGenericMonth${month}`],
        id: { month, year: get(params, 'data.movementInfo.date', new Date()).getFullYear() }
      }
    }
  },
  week: {
    headerName: translations.txtGenericWeek,
    colId: 'week',
    wiskExpandFirst: true,
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
    enableRowGroup: true,
    hide: true,
    sortOrder: 250,
    keyCreator: params => JSON.stringify(params.value),
    valueFormatter: params => params.value.title,
    valueGetter: params => {
      let dt = DateTime.fromJSDate(get(params, 'data.movementInfo.date', new Date())),
        week = dt.weekNumber,
        sort = get(params, 'data.movementInfo.date', new Date()).getTime(),
        title = (dt && `${translations.txtGenericWeek} ${week}, ${translations.txtGenericStartedOn} ${dt.startOf('week').toFormat('DD')}`) || ''

      return { colId: 'week', sort, title }
    }
  },
  image: {
    hide: false,
    sortOrder: 280
  },
  title: {
    hide: false,
    sort: null,
    sortOrder: 290
  },
  movementDistributor: {
    colId: 'movementDistributor',
    headerName: translations.txtGenericDistributor,
    minWidth: 100,
    enableRowGroup: true,
    filterType: 'list',
    ...agGridFilters.text,
    cellRendererParams: {
      getFilterItems: () => distributors,
      trackBy: 'title'
    },
    valueGetter: params => get(distributorsById[get(params, 'data.movementInfo.operation.distributor_id')], 'title', '')
  },
  family: { hide: true },
  category: { hide: true },
  movementId: {
    headerName: translations.txtMovementEditInvoiceId,
    colId: 'movementId',
    field: 'movement_id',
    hide: true,
    valueGetter: params => get(params, 'data.movementInfo.movement_id', '')
  },
  timestamp: {
    headerName: translations.txtGenericTimestamp,
    colId: 'timestamp',
    minWidth: 100,
    ...agGridFilters.date,
    valueFormatter: params => formatDate(params.value),
    valueGetter: params => get(params, 'data.movementInfo.date', '')
  },
  date: {
    headerName: translations.txtGenericDate,
    colId: 'date',
    minWidth: 100,
    enableRowGroup: true,
    cellRendererParams: {
      useValueFormatter: true
    },
    valueFormatter: params => formatDate(params.value, { format: 'DD' }),
    keyCreator: params =>
      JSON.stringify({
        colId: 'date',
        title: formatDate(params.value, { format: 'DD' }),
        sort: params.value.getTime()
      }),
    valueGetter: params => {
      let value = get(params, 'data.movementInfo.date', '')
      return (
        value &&
        DateTime.fromJSDate(value)
          .startOf('day')
          .minus({ hours: venue.business_day_starting_hour - DateTime.fromJSDate(value).hour })
          .toJSDate()
      )
    }
  },
  operation: {
    headerName: translations.txtGenericOperation,
    colId: 'operation',
    minWidth: 100,
    filterType: 'list',
    cellRendererParams: {
      getFilterItems: () => [
        { id: translations.groupMovementTypes.intake, title: translations.groupMovementTypes.intake },
        { id: translations.groupMovementTypes.return, title: translations.groupMovementTypes.return },
        { id: translations.groupMovementTypes.transfer_out, title: translations.groupMovementTypes.transfer_out }
      ]
    },
    width: 100,
    enableRowGroup: true,
    maxWidth: 200,
    valueGetter: params => translations.groupMovementTypes[get(params, 'data.movementInfo.operation.type')] || get(params, 'data.movementInfo.operation.type', '')
  },
  description: {
    headerName: translations.txtGenericDescription,
    colId: 'description',
    minWidth: 100,
    width: 400,
    maxWidth: 600,
    ...agGridFilters.text,
    valueGetter: params => get(params, 'data.movementInfo.description', '')
  },
  depletionReason: {
    colId: 'depletionReason',
    sortOrder: 1450,
    headerName: translations.txtMovementDepletionReason,
    minWidth: 100,
    width: 120,
    enableRowGroup: true,
    ...agGridFilters.text,
    field: 'movementInfo.depletion_reason',
    remove: !movementTypes.includes('depletion')
  },
  from: {
    headerName: translations.columnFromArea,
    colId: 'from',
    minWidth: 100,
    width: 400,
    enableRowGroup: true,
    maxWidth: 600,
    ...agGridFilters.text,
    valueGetter: params => get(params, 'data.movementInfo.operation.from.title', '')
  },
  to: {
    headerName: translations.columnToArea,
    colId: 'to',
    minWidth: 100,
    width: 400,
    enableRowGroup: true,
    maxWidth: 600,
    ...agGridFilters.text,
    valueGetter: params => get(params, 'data.movementInfo.operation.to.title', '')
  },
  photos: {
    colId: 'photos',
    headerName: translations.txtGenericPhotos,
    minWidth: 100,
    width: 100,
    cellClass: ['cell-more-details'],
    cellRenderer: 'CellMoreDetails',
    cellRendererParams: {
      icon: 'wisk-show',
      getHideButton: (id, data) => !data.attachments,
      excludeFromExport: true,
      onClick: openPhotos
    },
    valueGetter: params => ({
      id: get(params, 'data.movementInfo.movement_id'),
      group: !!params.node.group,
      photos: get(params, 'data.movementInfo.stats.attachments'),
      input_value: !!get(params, 'data.movementInfo.stats.attachments')
    })
  },
  person: {
    colId: 'person',
    headerName: translations.txtGenericPerson,
    minWidth: 100,
    ...agGridFilters.text,
    valueGetter: params => get(params, 'data.movementInfo.stats.started_by')
  },
  invoiceNumber: {
    colId: 'invoiceNumber',
    minWidth: 100,
    hide: true,
    ...agGridFilters.text,
    cellRenderer: 'CellText',
    enableRowGroup: true,
    headerName: translations.txtMovementEditInvoiceNumber,
    cellRendererParams: {
      siblingsVisibleInGroup: ['date']
    },
    valueGetter: params => ({ input_value: get(params, 'data.movementInfo.operation.invoice_number', '') })
  },
  type: {
    colId: 'type',
    minWidth: 100,
    headerName: translations.txtGenericType,
    enableRowGroup: true,
    cellRenderer: 'CellText',
    cellRendererParams: {},
    ...agGridFilters.text,
    valueGetter: params => ({ input_value: translations.groupScannedInvoicesGLAccountGridLineTypes[get(params, 'data.movementInfo.line.type')] })
  },
  glAccount: {
    colId: 'glAccount',
    minWidth: 100,
    // sortOrder: 200,
    headerName: translations.txtGLAccount,
    enableRowGroup: true,
    remove: !venue?.accounting_enabled,
    cellRenderer: 'CellText',
    cellRendererParams: { readonly: true },
    keyCreator: params => (get(params, 'value.input_value') === '-' ? translations.txtGenericUnmapped : get(params, 'value.input_value', translations.txtGenericUnmapped)),
    ...agGridFilters.text,
    valueGetter: params => ({ input_value: (get(params, 'data.glAccount') || { title: '-' }).title })
  },
  invoiceTotal: {
    colId: 'invoiceTotal',
    minWidth: 100,
    hide: true,
    headerName: translations.txtMovementEditInvoiceTotalPaper,
    cellRenderer: 'CellNumber',
    cellRendererParams: { decimals: 2, readonly: true, prefix: window.WiskGlobals.currency },
    ...agGridFilters.number,
    cellClass: ['currency'],
    valueGetter: params => ({ input_value: get(params, 'data.movementInfo.operation.invoice_total') })
  },
  lineMeasurement: {
    colId: 'lineMeasurement',
    sortOrder: 291,
    headerName: translations.txtMovementsLineMeasurement,
    cellRenderer: 'CellText',
    ...agGridFilters.text,
    cellClass: ['text-end'],
    minWidth: 100,
    maxWidth: 200,
    autoHeight: true,
    sort: 'asc',
    enableRowGroup: true,
    keyCreator: params => params.value.input_value,
    cellRendererParams: {
      translate: translations.translate,
      readonly: true,
      secondaryInfoComponentType: 'extraText'
    },
    valueGetter: params => {
      let measurements = get(params, 'data.movementInfo.line.value.measurements', []),
        first = measurements[0] || {},
        caseSize = (first && first.case_size) || (params.data && params.data.case_size) || 1,
        formatted = `${get(first, 'quantity', '')} ${get(first, 'unit_of_measurement', get(first, 'type', ''))}`

      return {
        id: params.data && params.data.item_id,
        input_value: formatted,
        item: params.data,
        extraText: first.type === 'case' && caseSize > 1 ? `${translations.txtGenericCaseSize}: ${caseSize}`.toLowerCase() : ''
      }
    }
  },
  costUnit: {
    colId: 'costUnit',
    hide: false,
    sortOrder: 2200
  },
  invoiceCostUnit: {
    colId: 'invoiceCostUnit',
    hide: true,
    sortOrder: 1700,
    headerName: translations.txtGenericInvoiceCostUnit,
    cellRenderer: 'CellNumber',
    cellClass: ['currency'],
    minWidth: 100,
    width: 100,
    cellRendererParams: {
      translate: translations.translate,
      type: 'price_per_uom',
      infoComponentType: 'costUnitInfo',
      secondaryInfoComponentType: 'extraText',
      decimals: 2,
      prefix: window.WiskGlobals.currency
    },
    valueGetter: params => {
      let invoice = params.data?.movementInfo?.line.value || {}
      return {
        id: params.data?.item_distributor_id ?? params.data?.variants?.find(v => v && v.is_default)?.id,
        item_id: params.data?.item_id,
        price: invoice.price,
        extraText: invoice.price?.type === 'case' ? `${currencyFormatHideZero(invoice.price.value)} / ${translations.case}` : '',
        readonly: !!invoice.intakes?.length || !!params.data?.linked_subrecipe_id,
        input_value: invoice.price_per_unit === undefined ? invoice.price?.value : invoice.price_per_unit
      }
    }
  },
  movementTotal: {
    colId: 'movementTotal',
    headerName: translations.columnItemTotal,
    minWidth: 100,
    cellRenderer: 'CellNumber',
    cellClass: ['currency'],
    aggFunc: 'wiskSum',
    cellRendererParams: {
      decimals: 2,
      readonly: true,
      translate: translations.translate,
      infoComponentType: 'extraText',
      forceInfoComponent: true,
      prefix: window.WiskGlobals.currency
    },
    ...agGridFilters.number,
    valueGetter: params => {
      let measurements = get(params, 'data.movementInfo.line.value.measurements', []),
        foundScale = measurements.find(m => m.unit_of_measurement === 'scale'),
        foundSlider = measurements.find(m => m.unit_of_measurement === 'slider'),
        foundUnit = measurements.find(m => m.unit_of_measurement === 'unit'),
        units = (foundUnit && foundUnit.value) || 0,
        foundCase = measurements.find(m => m.unit_of_measurement === 'case'),
        extraText = '',
        partialMl = 0

      if (foundScale) {
        partialMl = foundScale.value
      }
      if (foundSlider) {
        partialMl = foundSlider.percentage_remaining * foundSlider.volume
      }

      if (foundCase) {
        extraText += `${foundCase.value} ${translations.txtGenericCases}`
      }
      if (units) {
        if (extraText) {
          extraText += ', '
        }
        if (partialMl && params.data.volume) {
          units += round(partialMl / params.data.volume, 2)
        }

        extraText += `${units} ${translations.txtGenericUnits}`
      }

      let ret = {
        id: params.data && params.data.item_id,
        input_value: get(params, 'data.movementInfo.line.value.total', 0),
        extraText
      }
      return ret
    }
  },
  more: {
    colId: 'more',
    sortOrder: 2700,
    headerName: translations.columnDetails,
    minWidth: 100,
    width: 100,
    suppressColumnsToolPanel: true,
    suppressSortingToolPanel: true,
    suppressFiltersToolPanel: true,
    cellClass: ['cell-more-details'],
    cellRenderer: 'CellMoreDetails',
    cellRendererParams: {
      onClick: openDetails
    },
    valueGetter: params => ({
      id: get(params, 'data.movementInfo.movement_id'),
      group: !!params.node.group
    })
  }
})

const getItemsGridColumns = ({
  translations,
  save,
  currentPermissionsByType,
  venue,
  openMeasurement,
  openPriceEditor,
  glAccountsById,
  activeGLAccounts,
  activeTaxes,
  taxesById,
  setGlobalAction,
  disabled,
  accountingVenueClasses
}) => ({
  dropdownMenu: {
    forceOverrideValuegetter: true,
    valueGetter: params => ({
      group: params.node.group,
      items: [
        {
          onClick: item => {
            save({
              type: 'item_delete',
              value: { item_distributor_id: item.item_distributor_id, sort_order: item.movement_info.sort_order, order: item.movement_info.sort_order }
            })
          },
          disabled,
          label: translations.txtGenericRemove,
          value: params.data
        }
      ]
    })
  },
  image: {
    hide: false
  },
  title: {
    hide: false,
    sort: null,
    wiskBottomAggregation: translations.txtGenericTotal + ':'
  },
  distributorCode: { hide: false },
  distributor: { hide: true },
  family: { hide: true },
  category: { hide: true },
  lineNo: {
    colId: 'lineNo',
    hide: true,
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
    enableRowGroup: false,
    sort: 'asc',
    headerName: translations.txtGenericLineNumber,
    valueGetter: params => get(params, 'data.sort_order', 0)
  },
  itemMeasurement: {
    colId: 'itemMeasurement',
    sortOrder: 400,
    headerName: translations.txtMovementsItemMeasurement,
    cellRenderer: 'CellText',
    ...agGridFilters.text,
    cellClass: ['text-end'],
    minWidth: 100,
    maxWidth: 150,
    enableRowGroup: true,
    keyCreator: params => params.value.input_value,
    cellRendererParams: {
      translate: translations.translate,
      readonly: true,
      infoComponentType: 'extraText'
    },
    valueGetter: params => {
      let measurement = get(params, 'data.movement_info.item_distributor_measurement'),
        caseSize = (measurement && measurement.case_size) || (params.data && params.data.case_size) || 1,
        formatted = `${get(measurement, 'quantity', '')} ${get(measurement, 'unit_of_measurement', get(measurement, 'type', ''), '')}`

      return {
        id: params.data && params.data.item_id,
        input_value: formatted,
        item: params.data,
        extraText: measurement && measurement.type === 'case' && caseSize > 1 ? `${translations.txtGenericCaseSize}: ${caseSize}`.toLowerCase() : ''
      }
    }
  },
  lineMeasurement: {
    colId: 'lineMeasurement',
    sortOrder: 410,
    headerName: translations.txtMovementsLineMeasurement,
    cellRenderer: 'CellText',
    ...agGridFilters.text,
    cellClass: ['text-end'],
    minWidth: 100,
    maxWidth: 200,
    enableRowGroup: true,
    keyCreator: params => params.value.input_value,
    cellRendererParams: {
      translate: translations.translate,
      readonly: true,
      extraButtons: [
        {
          id: 1,
          action: openMeasurement,
          icon: 'wisk-edit',
          getVisible: () => !disabled
        }
      ],
      infoComponentType: 'extraButtons',
      secondaryInfoComponentType: 'extraText'
    },
    valueGetter: params => {
      let measurements = get(params, 'data.movement_info.measurements', []),
        sortOrder = get(params, 'data.movement_info.sort_order'),
        first = measurements[0] || {},
        caseSize = (first && first.case_size) || (params.data && params.data.case_size) || 1,
        formatted = `${get(first, 'quantity', '')} ${get(first, 'unit_of_measurement', get(first, 'type', ''))}`

      return {
        id: params.data && params.data.item_id,
        input_value: formatted,
        item: params.data,
        sortOrder,
        extraText: first.type === 'case' && caseSize > 1 ? `${translations.txtGenericCaseSize}: ${caseSize}`.toLowerCase() : ''
      }
    }
  },
  priceInMovement: {
    colId: 'priceInMovement',
    hide: false,
    sortOrder: 1920,
    headerName: translations.txtGenericCost,
    cellRenderer: 'CellText',
    minWidth: 150,
    maxWidth: 200,
    cellClass: params => ['text-center', get(params, 'data.movement_info.change_per_unit') > 0 ? 'text-danger' : get(params, 'data.movement_info.change_per_unit') < 0 ? 'text-success' : ''],
    cellRendererParams: {
      readonly: true,
      translate: translations.translate,
      extraButtons: [
        {
          id: 1,
          action: openPriceEditor,
          icon: 'wisk-edit',
          getVisible: () => !disabled
        }
      ],
      infoComponentType: 'extraButtons',
      secondaryInfoComponentType: 'extraText',
      tertiaryInfoComponentType: 'costChangeInfo'
    },
    valueGetter: params => {
      let type = get(params, 'data.movement_info.price.type', 'unit')

      if (type === 'manual') {
        type = `${get(params, 'data.movement_info.price.measurement.quantity')} ${get(params, 'data.movement_info.price.measurement.unit_of_measurement')}`
      }
      let value = currencyFormatHideZero(get(params, 'data.movement_info.price.value', 0)),
        formatted = value ? `${value} / ${type}` : '',
        sortOrder = get(params, 'data.movement_info.sort_order'),
        discount = get(params, 'data.movement_info.price.price_discount', { value: get(params, 'data.movement_info.price.discount') }),
        extraText = (discount.value && `${translations.txtGenericDiscount}: ` + (discount.type === 'percent' ? `${discount.value * 100} %` : `${window.WiskGlobals.currency}${discount.value}`)) || ''

      return {
        id: params.data && params.data.item_id,
        input_value: formatted,
        sortOrder,
        data: params.data && params.data.movement_info,
        extraText,
        costChangeInfo: {
          previous_cost_per_unit: get(params, 'data.movement_info.previous_cost_per_unit'),
          new_cost_per_unit: get(params, 'data.movement_info.price_per_unit'),
          change_per_unit: get(params, 'data.movement_info.change_per_unit'),
          change_percentage: (get(params, 'data.movement_info.change_per_unit') * 100) / get(params, 'data.movement_info.previous_cost_per_unit'),
          total_change: get(params, 'data.movement_info.total_change')
        }
      }
    }
  },
  priceInMovementWithoutTax: {
    colId: 'priceInMovementWithoutTax',
    hide: true,
    sortOrder: 1925,
    headerName: translations.txtMovementEditPriceWithoutTax,
    cellRenderer: 'CellText',
    minWidth: 100,
    maxWidth: 200,
    cellClass: ['text-center'],
    cellRendererParams: {
      readonly: true,
      translate: translations.translate
    },
    valueGetter: params => {
      let type = get(params, 'data.movement_info.price.type', 'unit')

      if (type === 'manual') {
        type = `${get(params, 'data.movement_info.price.measurement.quantity')} ${get(params, 'data.movement_info.price.measurement.unit_of_measurement')}`
      }
      let sortOrder = get(params, 'data.movement_info.sort_order'),
        formatted = `${currencyFormatHideZero(get(params, 'data.movement_info.price_excluding_taxes.value', 0))} / ${type}`

      return {
        id: params.data && params.data.item_id,
        input_value: formatted,
        sortOrder,
        data: params.data && params.data.movement_info
      }
    }
  },
  retailPrice: {
    hide: true,
    sortOrder: 1930
  },
  glAccount: {
    colId: 'glAccount',
    sortOrder: 2000,
    headerName: translations.txtGLAccount,
    enableRowGroup: true,
    cellRenderer: cellRenderers.CellPopMultiselect,
    remove: !venue?.accounting_enabled,
    valueSetter: params => {
      if (params?.data?.movement_info) {
        params.data.movement_info.gl_account_id = params.newValue
      }

      return true
    },
    minWidth: 150,
    cellEditor: 'CellPopMultiselect',
    editable: !!currentPermissionsByType.gl_account_assign && !disabled,
    cellEditorParams: {
      autoOpen: true,
      translate: translations.translate,
      readonly: !currentPermissionsByType.gl_account_assign || disabled,
      updateValue: params => {
        let value = { ...params.data.data },
          from = { ...params.data.data }

        value.gl_account_id = params.value

        save({ ...params, value, from })
      },
      type: 'item_update',
      trackBy: 'id',
      getItems: () => activeGLAccounts,
      addNewItem: {
        action: (id, searchQuery, value, callbackRefresh) => {
          setGlobalAction({
            type: 'glAccountEdit',
            action: {
              id: 0,
              title: searchQuery,
              onChange: glAccount => {
                callbackRefresh(glAccount)
              }
            }
          })
        },
        label: translations.txtGLAccountNew
      }
    },
    ...agGridFilters.text,
    valueGetter: params => ({
      id: params.data && params.data.item_id,
      input_value: get(glAccountsById, get(params, 'data.movement_info.gl_account_id'), { title: '-' }),
      data: params.data && params.data.movement_info
    })
  },
  tax: {
    colId: 'tax',
    sortOrder: 2100,
    headerName: translations.txtTax,
    enableRowGroup: true,
    cellRenderer: cellRenderers.CellPopMultiselect,
    valueSetter: params => {
      if (params?.data?.movement_info) {
        params.data.movement_info.tax_rate_id = params.newValue
      }

      return true
    },
    minWidth: 150,
    editable: !!currentPermissionsByType.tax_rate_assign && !disabled,
    cellEditor: 'CellPopMultiselect',
    remove: !venue.taxes_enabled,
    cellEditorParams: {
      autoOpen: true,
      translate: translations.translate,
      infoComponentType: 'extraText',
      extraTextPositionPadding: '11px',
      forceInfoComponent: true,
      readonly: !currentPermissionsByType.tax_rate_assign || disabled,
      updateValue: params => {
        let value = { ...params.data.data },
          from = { ...params.data.data }

        value.tax_rate_id = params.value

        save({ ...params, value, from })
      },
      type: 'item_update',
      trackBy: 'id',
      getItems: () => activeTaxes,
      addNewItem: {
        action: (id, searchQuery, value, callbackRefresh) => {
          setGlobalAction({
            type: 'taxEdit',
            action: {
              id: 0,
              title: searchQuery,
              onChange: tax => {
                callbackRefresh(tax)
              }
            }
          })
        },
        label: translations.txtTaxesNew
      }
    },
    ...agGridFilters.text,
    valueGetter: params => ({
      id: params.data && params.data.item_id,
      input_value: get(taxesById, get(params, 'data.movement_info.tax_rate_id'), { title: '-' }),
      data: params.data && params.data.movement_info,
      extraText: currencyFormatHideZero(get(params, 'data.movement_info.total', 0) - get(params, 'data.movement_info.subtotal', 0))
    })
  },
  accountingClassId: {
    colId: 'accountingClassId',
    sortOrder: 2200,
    headerName: translations.txtMovementAccountingQuickbooksClass,
    enableRowGroup: true,
    cellRenderer: cellRenderers.CellPopMultiselect,
    valueSetter: params => {
      if (params?.data?.movement_info) {
        params.data.movement_info.accounting_venue_class_id = params.newValue
      }

      return true
    },
    minWidth: 150,
    editable: !disabled,
    cellEditor: 'CellPopMultiselect',
    remove: !venue.accounting_integration?.is_using_classes || venue.accounting_integration?.type !== 'quickbooks',
    cellEditorParams: {
      autoOpen: true,
      translate: translations.translate,
      readonly: disabled,
      updateValue: params => {
        let value = { ...params.data.data },
          from = { ...params.data.data }

        value.accounting_venue_class_id = params.value

        save({ ...params, value, from })
      },
      type: 'item_update',
      trackBy: 'id',
      getItems: () => accountingVenueClasses
    },
    ...agGridFilters.text,
    valueGetter: params => ({
      id: params.data && params.data.item_id,
      input_value: accountingVenueClasses.find(c => c.id === get(params, 'data.movement_info.accounting_venue_class_id')),
      data: params.data && params.data.movement_info
    })
  },
  subTotalMovement: {
    colId: 'subTotalMovement',
    cellRenderer: 'CellNumber',
    cellClass: ['text-end', 'pt-2', 'pe-2', 'currency'],
    minWidth: 100,
    maxWidth: 200,
    pinned: null,
    sortOrder: 2600,
    headerName: translations.txtGenericSubTotal,
    aggFunc: 'wiskSum',
    cellRendererParams: {
      translate: translations.translate,
      infoComponentType: 'extraText',
      forceInfoComponent: true,
      readonly: true,
      decimals: 2,
      prefix: window.WiskGlobals.currency
    },
    valueGetter: params => {
      let measurements = (params.data && params.data.movement_info && params.data.movement_info.measurements) || [],
        foundUnit = measurements.find(m => m.unit_of_measurement === 'unit'),
        units = (foundUnit && foundUnit.value) || 0,
        foundCase = measurements.find(m => m.unit_of_measurement === 'case'),
        extraText = ''

      if (foundCase) {
        extraText += `${foundCase.value} ${translations.txtGenericCases}`
      }
      if (units) {
        if (extraText) {
          extraText += ', '
        }

        extraText += `${units} ${translations.txtGenericUnits}`
      }

      return {
        id: params.data && params.data.item_id,
        input_value: (params.data && params.data.movement_info && params.data.movement_info.subtotal) || 0,
        extraText
      }
    }
  },
  totalMovement: {
    colId: 'totalMovement',
    cellRenderer: 'CellNumber',
    cellClass: ['text-end', 'pt-2', 'pe-2', 'currency'],
    minWidth: 100,
    maxWidth: 200,
    pinned: 'right',
    wiskGetBottomAggregationValue: rowData => get(rowData, 'movement_info.total', 0),
    sortOrder: 2700,
    headerName: translations.txtGenericTotal,
    aggFunc: 'wiskSum',
    cellRendererParams: {
      translate: translations.translate,
      infoComponentType: 'extraText',
      forceInfoComponent: true,
      readonly: true,
      decimals: 2,
      prefix: window.WiskGlobals.currency
    },
    valueGetter: params => {
      let measurements = (params.data && params.data.movement_info && params.data.movement_info.measurements) || [],
        foundUnit = measurements.find(m => m.unit_of_measurement === 'unit'),
        units = (foundUnit && foundUnit.value) || 0,
        foundCase = measurements.find(m => m.unit_of_measurement === 'case'),
        extraText = ''

      if (foundCase) {
        extraText += `${foundCase.value} ${translations.txtGenericCases}`
      }
      if (units) {
        if (extraText) {
          extraText += ', '
        }

        extraText += `${units} ${translations.txtGenericUnits}`
      }

      return {
        id: params.data && params.data.item_id,
        input_value: params?.data?.movement_info?.total || 0,
        extraText
      }
    }
  }
})

const getInvoiceGLAccountsGridColumns = ({ translations, glAccountsById }) => [
  {
    colId: 'title',
    sortOrder: 100,
    headerName: translations.txtGenericTitle,
    cellRenderer: 'CellText',
    cellRendererParams: { readonly: true },
    ...agGridFilters.text,
    valueGetter: params => ({ input_value: get(params, 'data.title') })
  },
  {
    colId: 'glAccount',
    sortOrder: 200,
    headerName: translations.txtGLAccount,
    enableRowGroup: true,
    cellRenderer: 'CellText',
    keyCreator: params => (get(params, 'value.input_value') === '-' ? translations.txtGenericUnmapped : get(params, 'value.input_value', translations.txtGenericUnmapped)),
    cellRendererParams: { readonly: true },
    ...agGridFilters.text,
    valueGetter: params => ({ input_value: get(glAccountsById, get(params, 'data.gl_account_id'), { title: '-' }).title })
  },
  {
    colId: 'cost',
    sortOrder: 300,
    headerName: translations.txtGenericCost,
    cellRenderer: 'CellNumber',
    cellClass: ['currency'],
    minWidth: 100,
    width: 100,
    cellRendererParams: {
      translate: translations.translate,
      secondaryInfoComponentType: 'extraText',
      readonly: true,
      extraTextPosition: 'right',
      prefix: window.WiskGlobals.currency,
      decimals: 2
    },
    valueGetter: params => {
      let price = get(params, 'data.price') || { type: '', value: 0 }
      return {
        id: get(params, 'data.id'),
        extraText: '/ ' + price.type,
        input_value: price.value
      }
    }
  },
  {
    colId: 'measurement',
    sortOrder: 400,
    headerName: translations.txtGenericMeasurement,
    cellRenderer: 'CellText',
    ...agGridFilters.text,
    cellClass: ['text-end'],
    minWidth: 100,
    maxWidth: 150,
    sort: 'asc',
    enableRowGroup: true,
    keyCreator: params => params.value.input_value,
    cellRendererParams: {
      translate: translations.translate,
      readonly: true,
      extraTextPosition: 'right',
      secondaryInfoComponentType: 'extraText'
    },
    valueGetter: params => {
      let measurement = get(params, 'data.measurement'),
        caseSize = (measurement && measurement.case_size) || (params.data && params.data.case_size) || 1,
        formatted = `${get(measurement, 'quantity')} ${get(measurement, 'unit_of_measurement', get(measurement, 'type'))}`

      return {
        id: params.data && params.data.item_id,
        input_value: formatted,
        item: params.data,
        extraText: measurement && measurement.type === 'case' && caseSize > 1 ? `${translations.txtGenericCaseSize}: ${caseSize}`.toLowerCase() : ''
      }
    }
  },
  {
    colId: 'type',
    sortOrder: 1300,
    headerName: translations.txtGenericType,
    enableRowGroup: true,
    cellRenderer: 'CellText',
    cellRendererParams: {},
    ...agGridFilters.text,
    valueGetter: params => ({ input_value: translations.groupScannedInvoicesGLAccountGridLineTypes[get(params, 'data.scan_invoice_line_type')] })
  },
  {
    colId: 'total',
    sortOrder: 1400,
    headerName: translations.txtGenericTotal,
    cellRenderer: 'CellNumber',
    cellClass: ['currency'],
    aggFunc: 'wiskSum',
    cellRendererParams: { decimals: 2, readonly: true, prefix: window.WiskGlobals.currency },
    ...agGridFilters.number,
    valueFormatter: params => currencyFormatHideZero(params.value),
    valueGetter: params => ({ input_value: get(params, 'data.total') })
  }
]

const getGridColumnsFlatByAttributes = ({ translations, flattenBy, setGlobalAction, familiesById, categoriesById, glAccountsById, openMovementsPopup }) => [
  {
    headerName: translations.txtGenericYear,
    colId: 'year',
    sortOrder: 200,
    remove: !flattenBy.includes('year'),
    wiskExpandFirst: true,
    enableRowGroup: true,
    keyCreator: params => (params.value && JSON.stringify({ colId: 'year', sort: params.value, title: params.value, id: params.value })) || '',
    valueGetter: params => {
      if (params?.data?.year && params?.data?.month) {
        let date = convertYearMonthToDate(params?.data?.year, params?.data?.month)
        return date?.getFullYear() || ''
      }
      return ''
    }
  },
  {
    headerName: translations.txtGenericMonth,
    colId: 'month',
    sortOrder: 300,
    remove: !flattenBy.includes('month'),
    wiskExpandFirst: true,
    enableRowGroup: true,
    valueFormatter: params => params.value?.title || '',
    keyCreator: params => (params.value && JSON.stringify(params.value)) || '',
    valueGetter: params => {
      if (params?.data?.year && params?.data?.month) {
        let date = convertYearMonthToDate(params?.data?.year, params?.data?.month),
          month = (date?.getMonth && date.getMonth()) || 0

        return {
          colId: 'month',
          sort: month + 1,
          title: translations[`txtGenericMonth${month}`],
          id: date && { month, year: date.getFullYear() }
        }
      }
      return ''
    }
  },

  {
    sortOrder: 400,
    colId: 'invoiceNumber',
    headerName: translations.txtMovementEditInvoiceNumber,
    cellClass: ['text-center'],
    minWidth: 150,
    enableRowGroup: true,
    remove: !flattenBy.includes('invoice_number'),
    field: 'invoice_number'
  },
  {
    sortOrder: 500,
    colId: 'variantId',
    headerName: translations.txtVenueBottlesVariant,
    cellRenderer: cellRenderers.TextExtraButtons,
    cellClass: ['text-center'],
    minWidth: 250,
    enableRowGroup: true,
    remove: !flattenBy.includes('item_distributor_id'),
    valueGetter: params => {
      const item = params.data?.item || {}

      return {
        input_value: item.title,
        id: params?.data?.item_distributor_id,
        extraButtons: [
          {
            id: 'openItemEditor',
            icon: 'wisk-edit',
            getVisible: () => !!params?.data?.item_distributor_id,
            action: () => {
              setGlobalAction({
                type: 'itemEdit',
                action: {
                  variantId: params?.data?.item_distributor_id,
                  itemId: item.item_id
                }
              })
            }
          }
        ]
      }
    }
  },
  {
    sortOrder: 600,
    colId: 'familyId',
    headerName: translations.txtGenericFamily,
    cellClass: ['text-center'],
    minWidth: 150,
    enableRowGroup: true,
    remove: !flattenBy.includes('family_id'),
    valueGetter: params => {
      let family = familiesById[params?.data?.family_id] || {}

      return family.title
    }
  },
  {
    sortOrder: 700,
    colId: 'categoryId',
    headerName: translations.txtGenericCategory,
    cellClass: ['text-center'],
    minWidth: 150,
    enableRowGroup: true,
    remove: !flattenBy.includes('category_id'),
    valueGetter: params => {
      let category = categoriesById[params?.data?.category_id] || {}

      return category.title
    }
  },
  {
    sortOrder: 800,
    colId: 'glAccountId',
    headerName: translations.txtGLAccount,
    cellClass: ['text-center'],
    minWidth: 150,
    enableRowGroup: true,
    remove: !flattenBy.includes('gl_account_id'),
    valueGetter: params => {
      let glAccount = glAccountsById[params?.data?.gl_account_id] || {}

      return glAccount.title || translations.txtMovementFlatAggregatedReportGLAccountNotAssigned
    }
  },
  {
    sortOrder: 800,
    colId: 'distributorId',
    headerName: translations.txtGenericDistributor,
    cellClass: ['text-center'],
    minWidth: 150,
    enableRowGroup: true,
    remove: !flattenBy.includes('distributor_id'),
    valueGetter: params => {
      let distributor = params?.data?.distributor || {}

      return distributor.title
    }
  },
  {
    sortOrder: 900,
    colId: 'subTotal',
    headerName: translations.txtGenericSubTotal,
    cellClass: ['text-end'],
    minWidth: 90,
    maxWidth: 220,
    enableRowGroup: true,
    valueFormatter: params => currencyFormatHideZero(params.value),
    field: 'aggregated_metrics.subtotal'
  },
  {
    sortOrder: 1000,
    colId: 'taxes',
    headerName: translations.txtTaxes,
    cellClass: ['text-end'],
    minWidth: 90,
    maxWidth: 220,
    enableRowGroup: true,
    valueFormatter: params => currencyFormatHideZero(params.value),
    field: 'aggregated_metrics.taxes'
  },
  {
    sortOrder: 1100,
    colId: 'total',
    headerName: translations.txtGenericTotal,
    cellClass: ['text-end'],
    minWidth: 90,
    maxWidth: 220,
    enableRowGroup: true,
    valueFormatter: params => currencyFormatHideZero(params.value),
    field: 'aggregated_metrics.total'
  },
  {
    colId: 'more',
    sortOrder: 40000,
    headerName: translations.txtGenericMovements,
    minWidth: 100,
    width: 200,
    suppressColumnsToolPanel: true,
    suppressSortingToolPanel: true,
    suppressFiltersToolPanel: true,
    headerClass: ['text-center'],
    cellClass: ['cell-more-details'],
    cellRenderer: 'CellMoreDetails',
    cellRendererParams: { onClick: openMovementsPopup },
    valueGetter: params => ({
      id: params.data && params.data.id,
      group: !!params.node.group
    })
  }
]

export { getGridColumns, getGridColumnsFlatByItems, getGridOptions, getItemsGridColumns, getInvoiceGLAccountsGridColumns, getGridColumnsFlatByAttributes }
